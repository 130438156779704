

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 40%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.colorClass {
  background: linear-gradient(90deg, #fe4e9f 0%, #fdb04f 100%);
}
.fontClass {
  font-family: "Anonymous Pro";
}
.fontClass2 {
  font-family: "Roboto", sans-serif;
}

.background-container {
  background-image: url("../src/assets/Rectangle\ 9.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px; /* Adjust padding as needed */
}
.header-bg-class {
  background-image: url("../src/assets/Rectangle\ 6.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 326px;
  height: 139px;
}


.background-header::before {
  content: "";
  background-image: url("../src/assets/sol_sentry_bot_transparency_faded.png");

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.15; /* Adjust the opacity value as needed */
  filter:saturate(0);
  pointer-events: none; /* Ensure the pseudo-element doesn't interfere with content */

}

.background-header1 {
  background-image: url("../src/assets/Rectangle\ 17.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 17px;
}
.videClla {
  background: linear-gradient(#fe509e, #fdaf50);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.bg-cl {
  background-image: url("../src/assets/Rectangle\ 15.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 700px;
  margin: auto;
}

@keyframes scrollBackground {
  from {
    background-position: 0 0;
  }
  to {
      background-position: 0 100%;
  }
}

.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/second.png");
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  /*animation: scrollBackground 60s linear infinite;*/
  z-index: -1;
}

/* Padding on video, SolSentry title text needs to be bigger on mobile */

.background-header {
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/second.png");
  background-size: cover;
  background-repeat: repeat;
  /*animation: scrollBackground 60s linear infinite;*/
}

.secondHeader1 {
  background-image: url("../src/assets/Rectangle\ 15.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.card-main {
  background-image: url("../src/assets/Rectangle\ 8.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.card-main1 {
  background-image: url("../src/assets/Ellipse\ 1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right;
}
.order {
  background-image: url("../src/assets/Rectangle\ 24.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.text-bg {
  background-image: url("../src/assets/Rectangle\ 19.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.bg-cell{
  background-image: url("../src/assets/Rectangle\ 26.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center; 
}
.custom-select {
  appearance: none; /* Remove default arrow icon */
  -webkit-appearance: none; /* for older versions of Chrome/Safari */
  -moz-appearance: none; /* for older versions of Firefox */
  background-image: url('../src/assets/Vector\ 1.png'); /* Set custom icon */
  background-repeat: no-repeat;
  background-position: right center; /* Adjust position of the icon */
}
.custom-modal {
  & .modal-dialog {
    max-width: 700px;
    width: 100%;
    margin: auto;
  }
}

